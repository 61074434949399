
import {
  defineComponent,
  PropType,
} from "vue";
import { AReportDto } from "@/modules/analytical-reports/common/types";
import { AReportSaveData } from "@/modules/analytical-reports/services/ApiAReportsService/common/types/StatusAReport";
import {
  ButtonComponent,
  ModalComponent,
  TInputComponent,
} from "table";

export default defineComponent({
  name: "AnalyticalReportPublishModal",
  components: {
    ModalComponent,
    TInputComponent,
    ButtonComponent,
  },
  props: {
    aReportDto: {
      type: Object as PropType<AReportDto>,
      required: true,
    },
  },
  emits: ["submit", "close"],
  setup() {
    return {};
  },
  data() {
    return {
      formData: {
        areport_name: "",
      } as AReportSaveData,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.formData.areport_name = this.aReportDto.data.areport_name;
  },
  methods: {
    onSubmit() {
      this.$emit("submit", this.formData);
    },
  },
});
