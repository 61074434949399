import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","justify-content":"flex-end","margin-top":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TInputComponent = _resolveComponent("TInputComponent")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    style: { width: '780px' },
    visible: true,
    title: "Публикация аналитического отчёта",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    body: _withCtx(({ close }) => [
      _createVNode(_component_TInputComponent, {
        modelValue: _ctx.formData.areport_name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.areport_name) = $event)),
        required: true,
        caption: "Введите название для отчёта",
        onReset: _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.areport_name = ''))
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ButtonComponent, {
          class: "form-button",
          "data-type": "accent",
          onClick: _ctx.onSubmit
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Опубликовать ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}