
import {
  defineComponent,
  PropType,
} from "vue";
import { AReportDto } from "@/modules/analytical-reports/common/types";
import {
  AttrsComponent,
  Dictionary,
  TSelectComponent,
} from "table";
import { ApiVocUserDto } from "@/services/AppUsersService/types";
import { UserHelper } from "@/common/helpers/UserHelper";
import { AReportRepositoryDto } from "@/modules/analytical-reports/components/AnalyticalReportsCreateReport.vue";

export default defineComponent({
  name: "AnalyticalReportsReportParams",
  props: {
    aReportDto: {
      type: Object as PropType<AReportDto>,
      required: true,
    },
    aReportRepositoryDto: {
      type: Object as PropType<AReportRepositoryDto>,
    },
    usersDict: {
      type: Object as PropType<Dictionary<ApiVocUserDto>>,
      required: true,
    },
  },
  components: {
    TSelectComponent,
    AttrsComponent,
  },
  setup() {
    return {
      UserHelper,
    };
  },
  data() {
    return {};
  },
  async created() {
  },
  computed: {
    areportCreatorName(): string | undefined {
      if (!this.aReportDto.data.areport_creator) {
        return undefined;
      }

      return UserHelper.getName(
        this.usersDict[this.aReportDto.data.areport_creator],
        "",
        this.aReportDto.data.areport_creator,
      );
    },
  },
});
