import {
  AReportDto,
  AReportTableRepositoryDto,
} from "@/modules/analytical-reports/common/types";
import { TableAppData } from "@/common/types/TableAppData";
import {
  Dictionary,
  HelpHelper,
  PanelFiltersSet,
  TableDataSet,
  TableDataSetFactory,
  TableLocalRepository,
} from "table";
import { reactive } from "vue";
import { PanelFiltersSaveModule } from "@/classes/PanelFiltersSaveModule";
import { applyPanelFiltersData } from "@/common/helpers/applyPanelFiltersData";

export interface AReportDtoToTableResult {
  tables: Dictionary<TableAppData>;
  repository: AReportTableRepositoryDto | null;
  errorMessage?: string | null;
}

export async function aReportDtoToTable(
  aReportDto: AReportDto,
  helpHelper: HelpHelper,
): Promise<AReportDtoToTableResult> {
  const { repository, data } = aReportDto;
  const tables: Dictionary<TableAppData> = reactive({});
  for (const tableName in repository.tables) {
    const tableDto = repository.tables[tableName];
    const { headers, model: modelDtoArray, options: optionsDto } = tableDto;
    const uniqId = `${aReportDto.data.areport_id}_${tableName}`;
    const filtersSaveModule = new PanelFiltersSaveModule(uniqId);
    await filtersSaveModule.init();
    const filtersSet = new PanelFiltersSet(filtersSaveModule);
    filtersSet.subject.subscribe((data) =>
      applyPanelFiltersData(
        tables[tableName].tableDataSet as TableDataSet,
        data,
      ),
    );
    const factory = new TableDataSetFactory({
      tableName,
      types: repository.types!,
      repository: new TableLocalRepository(),
      rows: (data.form_table_data || {})[tableName],
      headers,
      modelDtoArray,
      uniqId,
      edit: {},
      tableDataSetOptions: {
        form_id: aReportDto.data.areport_id,
        helpHelper,
        display: {
          incCol: 1,
          incRow: 0,
          rowIndexCol: true,
        },
        logging: {},
        filtersSet,
        uniqueWordWrapSave: true,
        optionsDto,
      },
      enableAutoSaveSettings: true,
    });

    tables[tableName] = {
      factory,
      tableDataSet: await factory.create(),
      open: {
        info: true,
      },
      dto: tableDto,
    };
  }

  return {
    repository,
    tables,
  };
}
