
import {
  defineComponent,
  watch,
} from "vue";
import {
  ApiHelper,
  boolToString,
  ButtonComponent,
  dateFormat,
  Dictionary,
  DropdownComponent,
  NotificationsService,
  ScreenSpinner,
  TABLE_ICONS,
  toStr,
} from "table";
import {
  APP_ICONS,
  DEFAULT_DROPDOWN_PROPS,
} from "@/common/consts";
import AnalyticalReportsReportParams from "@/modules/analytical-reports/components/AnalyticalReportsReportParams.vue";
import { AReportDto } from "@/modules/analytical-reports/common/types";
import ApiAReportsService from "@/modules/analytical-reports/services/ApiAReportsService/ApiAReportsService";
import AnalyticalReportsTable from "@/modules/analytical-reports/components/AnalyticalReportsTable.vue";
import AppUsersService from "@/services/AppUsersService/AppUsersService";
import { ApiVocUserDto } from "@/services/AppUsersService/types";
import {
  AReportSaveData,
  getStatusAReportName,
} from "@/modules/analytical-reports/services/ApiAReportsService/common/types/StatusAReport";
import AnalyticalReportSaveModal from "@/modules/analytical-reports/components/AnalyticalReportSaveModal.vue";
import AnalyticalReportPublishModal from "@/modules/analytical-reports/components/AnalyticalReportPublishModal.vue";
import AnalyticalReportsCreateReport, { AReportRepositoryDto } from "@/modules/analytical-reports/components/AnalyticalReportsCreateReport.vue";
import { AReportsExtraInfo } from "@/modules/analytical-reports/services/ApiAReportsService/common/types/AReportsExtraInfo";
import { DropdownOption } from "table/dist/components/Dropdown/common/types/DropdownOption";
import { wrapNotifyAndAwaited } from "@/common/helpers/wrapNotifyAndAwaited";

export default defineComponent({
  name: "AnalyticalReportsReport",
  components: {
    ScreenSpinner,
    ButtonComponent,
    DropdownComponent,
    AnalyticalReportsCreateReport,
    AnalyticalReportPublishModal,
    AnalyticalReportSaveModal,
    AnalyticalReportsTable,
    AnalyticalReportsReportParams,
  },
  setup() {
    return {
      TABLE_ICONS,
      APP_ICONS,
      dateFormat,
      getStatusAReportName,
      boolToString,
      DEFAULT_DROPDOWN_PROPS,
    };
  },
  data() {
    const list = [
      {
        title: "Просмотр отчёта",
        id: "view-report",
      },
      {
        title: "Параметры отчёта",
        id: "params-report",
      },
    ] as const;

    return {
      tabs: {
        list,
        active: list[0].id as "view-report" | string,
      },
      aReportDto: null as null | AReportDto,
      usersDict: null as Dictionary<ApiVocUserDto> | null,
      heights: {
        topDiv: 0,
      },
      open: {
        analyticalReportSaveModal: false,
        analyticalReportPublishModal: false,
        recalculate: false,
        create: false,
      },
      aReportsExtraInfo: null as AReportsExtraInfo | null,
      aReportRepositoryDto: null as AReportRepositoryDto | null,
      loading: 0,
      error: null as any,
      errorMessage: "",
    };
  },
  async created() {
    watch(
      () => this.error,
      async () => {
        if (!this.error) {
          this.errorMessage = "";
          return;
        }
        if (typeof this.error === "object") {
          if ("json" in this.error) {
            const error = await ApiHelper.getError(this.error);
            if (error.type === "record_not_found_exception") {
              this.errorMessage = "Запись удалена или ещё не создана.";
              return;
            }
          }
        }

        this.errorMessage = toStr(this.error);
      },
    );

    window.addEventListener("resize", this.resizeHandler);

    this.loading++;
    await wrapNotifyAndAwaited([
      async () => {
        this.usersDict = await AppUsersService.getUsersDict();
      },
      async () => {
        this.aReportsExtraInfo = (await ApiAReportsService.getExtraInfo()).json;
      },
    ]);
    await this.init();
    this.loading--;
  },
  mounted() {
  },
  updated() {
    this.resizeHandler();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  watch: {
    params() {
      this.init();
    },
  },
  computed: {
    heightBeforeContentTab(): number {
      return [this.heights.topDiv, 50, 40].reduce((value, currentValue) => {
        value += currentValue;
        return value;
      });
    },
    params(): { a_report_id: string } {
      return this.$route.params as {
        a_report_id: string;
      };
    },
    optionsExport(): DropdownOption[] {
      return [
        {
          title: "В .xlsx",
          onClick: () =>
            ApiAReportsService.exportToXlsx(this.params.a_report_id),
          props: {
            class: "button-blue",
            style: { width: "100%" },
          },
        },
      ];
    },

    keysTables(): string[] {
      return Object.keys(this.aReportDto?.repository?.tables || {});
    },

    joinTabNamesTables(): string {
      return this.keysTables
        .map((x) => this.aReportDto?.repository.tables[x].tab_name)
        .join(", ");
    },
  },
  methods: {
    async init() {
      this.loading++;
      try {
        this.aReportDto = (
          await ApiAReportsService.getAReport(this.params.a_report_id)
        ).json;
      } catch (ex: any) {
        this.error = ex;
      }

      await ApiHelper.wrapNotifyError(async () => {
        if (!this.aReportDto) {
          return;
        }

        if (this.aReportDto.data.areport_params) {
          this.aReportRepositoryDto = await ApiAReportsService.getAReportParams(
            this.aReportDto.data.areport_params.areport_type,
          ).then((x) => x.json);
        }
      });
      this.loading--;
    },

    async onSave(formData: AReportSaveData) {
      if (!formData.areport_name) {
        NotificationsService.send({
          type: "error",
          title: "При сохранении произошла ошибка",
          text: "Заполните обязательные поля",
        });
        return;
      }

      await ApiHelper.wrapNotifyError(async () => {
        const result = await ApiAReportsService.saveAReport(
          this.aReportDto!.data.areport_id,
          formData,
        );
        this.aReportDto = result.json;
        this.open.analyticalReportSaveModal = false;
      });
    },

    async onPublish(formData: AReportSaveData) {
      if (!formData.areport_name) {
        NotificationsService.send({
          type: "error",
          title: "При публикации произошла ошибка",
          text: "Заполните обязательные поля",
        });
        return;
      }
      await ApiHelper.wrapNotifyError(async () => {
        const result = await ApiAReportsService.publishAReport(
          this.aReportDto!.data.areport_id,
          formData,
        );
        this.aReportDto = result.json;
        this.open.analyticalReportPublishModal = false;
      });
    },

    async onArchive() {
      await ApiHelper.wrapNotifyError(async () => {
        await ApiAReportsService.archiveAReport(
          this.aReportDto!.data.areport_id,
        );
        await this.$router.push("/analytical-reports");
      });
    },

    async onRemove() {
      await ApiHelper.wrapNotifyError(async () => {
        await ApiAReportsService.removeAReport(
          this.aReportDto!.data.areport_id,
        );
        this.$router.replace("/analytical-reports");
      });
    },

    async onRecalculate(aReportDto: AReportDto) {
      this.aReportDto = aReportDto;
      this.open.recalculate = false;
    },

    setHeights() {
      this.heights.topDiv = (this.$refs.topDiv as HTMLDivElement).clientHeight;
    },

    resizeHandler() {
      this.setHeights();
    },
  },
});
