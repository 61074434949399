
import { defineComponent, PropType } from "vue";
import { AReportDto } from "../common/types";
import {
  aReportDtoToTable,
  AReportDtoToTableResult,
} from "@/modules/analytical-reports/common/helpers/aReportDtoToTable";
import {
  BlueHelpComponent,
  boolToString,
  HelpHelper,
  PanelFilters,
  ScreenSpinner,
  TABLE_ICONS,
  TableActionsComponent,
  TableComponent,
  TableDataSet,
  TableMultiHeaders,
} from "table";
import { DEFAULT_LEFT_COLUMN_NAME } from "@/common/consts";
import { TableCellPointer } from "table/dist/components/TableComponent/common/types/TableCellPointer";

export default defineComponent({
  name: "AnalyticalReportsTable",
  props: {
    aReportDto: {
      type: Object as PropType<AReportDto>,
      required: true,
    },
    heightBeforeContentTab: {
      type: Number,
      required: true,
    },
  },
  components: {
    ScreenSpinner,
    TableActionsComponent,
    TableComponent,
    TableMultiHeaders,
    BlueHelpComponent,
    PanelFilters,
  },
  setup() {
    return {
      boolToString,
      Object,
      TABLE_ICONS,
      DEFAULT_LEFT_COLUMN_NAME,
    };
  },
  data() {
    return {
      tableData: null as null | AReportDtoToTableResult,
      helpHelper: new HelpHelper(),
      show: {
        table: null as null | string,
      },
    };
  },
  created() {
    this.initAReportDto();
  },
  computed: {
    table() {
      return this.tableData?.tables[this.show.table!];
    },

    keysTables() {
      return Object.keys(this.tableData?.tables || {});
    },
  },
  watch: {
    aReportDto() {
      this.initAReportDto();
    },
  },
  methods: {
    isTableEmpty(tableDataSet: TableDataSet) {
      return (
        !tableDataSet.isServerManagement &&
        (tableDataSet.modelUnref.length === 0 || tableDataSet.rows.length === 0)
      );
    },

    async initAReportDto() {
      this.tableData = null;
      this.show.table = null;

      this.tableData = await aReportDtoToTable(
        this.aReportDto,
        this.helpHelper,
      );

      this.show.table = Object.values(
        this.tableData.tables,
      )[0].factory.options.tableName;
    },

    setHelp(pointer: Required<TableCellPointer> | null) {
      if (!pointer) {
        this.helpHelper.setHelp(null);
        return;
      }

      const model = this.table!.tableDataSet.getModelOrUndefined(
        pointer.col_name,
      );
      this.helpHelper.setHelp(model?.modelDto.help || null);
    },
  },
});
